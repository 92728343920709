// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare function require(moduleName: string): any;

const version = require('../../package.json').version;

export const environment = {
  production: false,
  authUrl: 'http://test.idp.egov.kz',
  api_url: 'http://api.test.sc.egov.kz/',
  old_api: 'http://api.test.sc.egov.kz/',
  api_version: 'v1',
  VERSION: version,
  domain: '.egov.kz',
  workflowUrl: 'http://api.test.sc.egov.kz',
  frontUrl: 'http://test.sc.egov.kz',
  egovUrl: 'http://test.my.egov.kz/',
  contractIDS: {
    PLEDGE_ID: '654e69a3-750f-438e-87f4-27edc3dc8785', // залог
    CESSION_ID: '0acb8a03-3eba-4122-a6b5-30ca9112e4d4',
    HOME_ID: 'dcb172ed-6d47-4ba0-b609-47c88d52efcf', // баспана
    AUTO_ID: 'a013436b-0d95-468f-b065-84f18e7b7c19',
  },
  env: 'TEST'
};
// export const environment = {
//   production: true,
//   authUrl: 'https://idp.egov.kz',
//   api_url: 'https://api-sc.egov.kz/',
//   old_api: 'https://api-sc.egov.kz/',
//   api_version: 'v1',
//   VERSION: version,
//   domain: '.egov.kz',
//   workflowUrl: 'https://api-sc.egov.kz',
//   frontUrl: 'https://sc.egov.kz',
//   egovUrl: 'https://my.egov.kz/',
//   contractIDS: {
//     PLEDGE_ID: 'd70497ff-2b8a-4434-afef-68651b97aea0',
//     CESSION_ID: '9db0e7bf-490b-49c5-83af-7df0cd71bc9a',
//     HOME_ID: 'c6515f1c-1690-4cf0-9b02-b028696ce7d1',
//     AUTO_ID: '86671f52-c7f1-4334-b044-a645c2a84d40',
//   },
//   env: 'PROD'
// };
